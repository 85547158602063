/* Defining colors from the color palette */
:root {
  /* Original colors */
  --background-color: #3a2731; /* Dark Background */
  --accent-color-1: #02534e; /* Green Accent */
  --accent-color-2: #efc9a2; /* Light Peach Accent */
  --complementary-color: #7a5195; /* Complementary Purple */

  /* Darker shades */
  --background-color-darker: #20181c; /* Darker Background */
  --accent-color-1-darker: #01423e; /* Darker Green Accent */
  --accent-color-2-darker: #bfa081; /* Darker Light Peach Accent */
  --complementary-color-darker: #614077; /* Darker Complementary Purple */

  /* Shades of Orange */
  --rich-orange: #ff944d;
}

@font-face {
  font-family: 'Bangers';
  src: url('./assets/fonts/Bangers/Bangers-Regular.ttf') format('truetype');
  font-weight: normal; /* Regular */
  font-style: normal;
}


@font-face {
  font-family: 'Kalam-Light';
  src: url('./assets/fonts/Kalam/Kalam-Light.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Kalam';
  src: url('./assets/fonts/Kalam/Kalam-Regular.ttf') format('truetype');
  font-weight: normal; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Kalam-Bold';
  src: url('./assets/fonts/Kalam/Kalam-Bold.ttf') format('truetype');
  font-weight: bold; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('./assets/fonts/Comfortaa/static/Comfortaa-Regular.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
}



@font-face {
  font-family: 'Comfortaa-Bold';
  src: url('./assets/fonts/Comfortaa/static/Comfortaa-Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
}


@font-face {
  font-family: 'Amatic';
  src: url('./assets/fonts/Amatic_SC/AmaticSC-Regular.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Amatic-Bold';
  src: url('./assets/fonts/Amatic_SC/AmaticSC-Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

.price-info {
  font-size: 1.2em; /* Adjust size as needed */
  color: #270907; /* Using one of your theme colors */
  text-align: center;
  margin-bottom: 10px; /* Space above the mint button */
  font-family: 'Bangers';
  font-size: 2.75em;
}


.scrolling-images-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 columns of equal width */
  gap: 1em; /* Optional: Adds space between columns */
  justify-items: center; /* Center each scrolling container */
  margin-left: 12% ; /* Center the scrolling area */
  margin-right: 12%;
  background-color: #d5a179;
  padding-left: 5%;
  padding-bottom: 2%;
  padding-right: 5%;
  padding-top: 5%;
  border-radius: 15px;
  grid-template-areas: 
  "image1 image2 image3"
  ". button ."; 
}

.image-scroll-container {
  width: auto;
  height: 32em;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
}

/* Assign grid areas to image containers */
.image-scroll-container:nth-child(1) { grid-area: image1; }
.image-scroll-container:nth-child(2) { grid-area: image2; }
.image-scroll-container:nth-child(3) { grid-area: image3; }

.button-row {
  display: grid;
  grid-template-rows: repeat(2 1fr);
  grid-area: button; /* Assign to the button area, which spans all three columns */
  width: 100%;
  margin-top: 20px;
}

.scrolling-image {
  width: 100%; /* Make images expand full container width */
  height: auto; /* Adjust the height automatically */
  object-fit: contain; /* Ensure the images are fully visible */
  border-radius: 10px;
}

.nft-id-tab {
  position: absolute;
  bottom: -20px; /* Adjust based on your layout */
  left: 50%;
  transform: translateX(-50%); /* Centers the tab */
  width: 120px; /* Or any other width depending on your design needs */
  height: 40px; /* Height of the tab */
  background-color: #7a5195;
  color: #01423e;
  line-height: 40px; /* Aligns text vertically */
  text-align: center;
  font-weight: bold;
  clip-path: polygon(15% 0, 85% 0, 100% 100%, 0 100%); /* Creates trapezoidal shape */
}

.mint-button {
  background-color: #270907;
  color: #d5a179; 
  height: 2em; /* Fixed height for the button */
  padding: 0 1em; /* Horizontal padding of 1em, vertical padding removed */
  cursor: pointer;
  border: 2px solid var(--accent-color-1);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: 'Amatic-Bold';
  font-size: 1.5em;
  display: inline-flex; /* Use inline-flex to maintain button's inline behavior */
  align-items: center; /* This aligns the text vertically */
  justify-content: center; /* This centers the text horizontally */
  /* Other styles */
}


.mint-button:hover {
  background-color: var(--accent-color-1-darker);
  border-color: #d5a179; 
  color: #d5a179;
}

#countdown {
  display: flex;
  justify-content: center;
}

.time-section {
  text-align: center;
  margin: 0 10px;
}

.time-label {
  font-size: 0.8rem;
  color: #270907;
}

.app {
  color: #270907;
}

.app-background {
  background-color: #270907;
  min-height: 100vh; /* Ensure it covers the full height */
}

.gradient-divider {
  height: 2px; /* Adjust the thickness of the divider */
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, #b65e29 17%, rgba(255,255,255,0) 100%);
  margin: 20px 0; /* Adjust spacing around the divider */
  padding-top: 1%;
}
.gradient-divider-2 {
  height: 0.5%; /* Adjust the thickness of the divider */
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, #02534e 17%, rgba(255,255,255,0) 100%);
  margin-bottom: 1%; /* Adjust spacing around the divider */
  padding-top: 0.25%;
  margin-top: 3%;
}
.gradient-divider-3 {
  height: 0.5%; /* Adjust the thickness of the divider */
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, #02534e 17%, rgba(255,255,255,0) 100%);
  margin-bottom: 1%; /* Adjust spacing around the divider */
  padding-top: 0.25%;
}



.top-container {
  padding-top: 3%;
  text-align: center;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center;
  padding-bottom: 7%;
}
.time-container {
  padding-bottom: 1.5%;
}

.top-container h1 {
  font-family: 'Bangers';
  color: #d5a179;
  font-size: 48pt;
}

.const h1 {
  font-family: 'Bangers';
  color: #d5a179;
  font-size: 48pt; 
}

.const p {
  font-family: 'Amatic-Bold';
  color: #d5a179;
  font-size: 2em;
  margin-top: -1%;
}

.top-container h2 {
  font-family: 'Bangers';
  color: #d5a179;
  font-size: 36pt;
}
.top-container h3 {
  margin-top: -2%;
  font-family: 'Amatic-Bold';
  color: #d5a179;
  font-size: 4em;
}
.top-container h4 {
  font-family: 'Amatic-Bold';
  color: #d5a179;
  font-size: 2em;
  margin-top: -1%;
}

.top-container p {
  font-family: 'Amatic-Bold';
  color: #d5a179;
  font-size: 2em;
  margin-top: -1%;
}

.minting-text {
  font-family: 'Bangers';
  color: #270907;
  font-size: 24pt;
  text-align: center;
}


.top-container p {
  font-family: 'Comfortaa-Light';
  color: #d5a179;
  font-size: 14pt;
}

#countdown {
  display: flex;
  justify-content: space-around; /* This spreads out the child elements equally across the container's main axis */
  width: 100%; /* Ensures the container takes the full width */
}

/* For the timer numbers */
.time-section > span {
  font-family: 'Comfortaa', sans-serif; /* Fallback to sans-serif if Kalam is not available */
  font-size: 8em; /* Adjust size as needed */
  color: #d5a179; /* Change color as desired */
}

/* For the labels */
.time-label {
  font-family: 'Comfortaa-Light', sans-serif; /* Fallback to sans-serif if Kalam-Light is not available */
  font-size: 3.2rem; /* Existing size */
  color: #d5a179; /* Existing color, change if you want */
}

.time-section {
  flex: 1; /* This makes each .time-section grow equally to fill the container */
  text-align: center; /* Keeps the text aligned to the center */
}

.button-new {
  background-color: #d5a179;
  color: #270907; 
  height: 2em; /* Fixed height for the button */
  padding: 0 1em; /* Horizontal padding of 1em, vertical padding removed */
  cursor: pointer;
  border: 2px solid var(--accent-color-2);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: 'Amatic-Bold';
  font-size: 1.5em;
  display: inline-flex; /* Use inline-flex to maintain button's inline behavior */
  align-items: center; /* This aligns the text vertically */
  justify-content: center; /* This centers the text horizontally */
  /* Other styles */
}


.button-new:hover {
  background-color: var(--accent-color-1-darker);
  border-color: #d5a179; 
  color: #d5a179;
}

.button-bgd {
  background-color: #270907;
  color: #d5a179; 
  height: 2em; /* Fixed height for the button */
  padding: 0 1em; /* Horizontal padding of 1em, vertical padding removed */
  cursor: pointer;
  border: 2px solid var(--accent-color-1);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: 'Amatic-Bold';
  display: inline-flex; /* Use inline-flex to maintain button's inline behavior */
  align-items: center; /* This aligns the text vertically */
  justify-content: center; /* This centers the text horizontally */
  /* Other styles */
}


.button-bgd:hover {
  background-color: var(--accent-color-1-darker);
  border-color: #d5a179; 
  color: #d5a179;
}

.middle-container {
  padding-top: -3%;
  display: grid;
  grid-template-rows: repeat(2 1fr);
  justify-content: center;
  text-align: center;
}

.middle-container h3 {
  font-family: "Bangers";
  color: #d5a179;
  font-size: 2.5em;
  margin: 10px 0;
}

.middle-container p {
  font-family: 'Amatic-Bold';
  color: #d5a179;
  font-size: 2em;
  margin-top: -1%;
}

.middle-container-new p {
  font-family: 'Amatic-Bold';
  color: #270907;
  font-size: 2em;
  margin-top: -1%;
  text-align: center;
}

/* Container for the three columns */
.three-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates three columns of equal width */
  gap: 12.5%; /* Adjust the space between tiles */
  padding-top: 0%; /* Space around the grid */
  padding-left: 12.5%;
  padding-right: 12.5%;
  padding-bottom: 6.25%;
  align-items: start; /* Align items to the start of each cell */
}

/* Styling for each tile */
.tile {
  background-color: #d5a179; /* #ff944d with 30% transparency */
  padding: 20px; /* Padding inside each tile */
  border-radius: 8px; /* Rounded corners for the tiles */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  display: flex; /* Establishes a flex container */
  flex-direction: column; /* Stacks child elements vertically */
  align-items: center; /* Aligns children (text, button) on the cross-axis (horizontally, in this case) */
  justify-content: center; /* This centers the content along the main axis, useful if you adjust the height */
}

/* Tile headings */
.tile h4 {
  margin-top: 0;
  color: #270907; /* Dark grey color for the text */
  font-size: 18px; /* Adjust the font size as needed */
  text-align: center;
  font-family: "Amatic-Bold"
}

/* Tile paragraphs */
.tile p {
  color: #270907; /* Lighter grey for the paragraph text */
  font-size: 16px; /* Adjust the paragraph font size as needed */
  text-align: center;
  font-family: "Amatic";
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: center; /* This will center the grid items in the container if they don't fill the row */
  align-items: center;
  gap: 5em;
  padding: 10px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 7%;
}

.top-food-chain-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Set the number of columns to 2 */
  gap: 7em;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-left: 5%;
  margin-right: 5%;
}


.image-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d5a179;
  border: 2px solid #b65e29;
  border-radius: 8px;
  padding: 2em;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
}

.image-tile-new {
  margin-left: 33%;
  margin-right: 33%;
  align-items: center;
  justify-content: center;
  background-color: #d5a179;
  border: 2px solid #b65e29;
  border-radius: 8px;
  padding: 2em;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
}
.image-tile-new img {
  width: 80%; /* adjust this as needed to make images smaller than the tile */
  margin-bottom: 8px;
  border-radius: 15px;
}

.image-tile-new p {
  font-size: 20px;
  color: #270907;
  font-family: 'Comfortaa-Bold';
}

.image-tile img {
  width: 80%; /* adjust this as needed to make images smaller than the tile */
  margin-bottom: 8px;
  border-radius: 15px;
}

.image-tile p {
  font-size: 20px;
  color: #270907;
  font-family: 'Comfortaa-Bold';
}

.image-section h2 {
  font-family: 'Bangers';
  color: #d5a179;
  font-size: 42pt;
  text-align: center;
}

.nft-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 5em;
  padding: 20px;
  justify-items: center; /* Center tiles in each grid column */
  margin-left: 3.125%;
  margin-right: 3.125%;
}

.nft-id-tab {
  position: absolute;
  bottom: 0px; /* Adjust based on your layout, may need to be lower */
  left: 50%;
  transform: translateX(-50%); /* Centers the tab */
  min-width: 80px; /* Ensure it's wide enough to fit content */
  height: 40px; /* Height of the tab */
  background-color:  #270907;
  color: #d5a179;
  line-height: 40px; /* Aligns text vertically */
  text-align: center;
  font-size: 1.4em; /* Adjust font size as needed */
  font-weight: bold;
  font-family: "Amatic-Bold";
  clip-path: polygon(15% 0, 85% 0, 100% 100%, 0 100%); /* Creates trapezoidal shape */
  z-index: 10; /* Ensure it's above other elements */
}
.nft-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #d5a179;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 15%;
  position: relative;
  margin-bottom: 60px;
}

.nft-tile:hover {
  transform: translateY(-5px);
}

.nft-image {
  width: 100%;
  height: auto;
  display: block;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 20px;
}

.nft-gallery h3 {
  font-family: "Bangers";
  font-size: 1.5em;
  margin: 10px 0;
}

.trait-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  padding: 10px;
  gap: 5px;
  box-sizing: border-box;
  color: #270907;
}

.trait-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trait-name {
  font-weight: bold;
  font-size: 1.4rem;
  font-family: 'Amatic-Bold';
}

.trait-value {
  font-size: 1rem;
  text-align: center;
  font-family: 'Amatic-Bold';
}

.puff-port {
  background-color: #d5a179;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%; /* Adjust the width as needed */
  margin-left: auto;
  margin-right: auto;
}

.puff-port h2 {
  font-family: "Bangers";
  font-size: 2em;
  margin: 10px 0;
  color: #270907;
}

.puff-port p {
  font-family: "Amatic";
  font-size: 2em;
  margin: 10px 0;
  color: #270907;
}

.puff-port input, .puff-port select, .puff-port button {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1.5em;
  background-color: #270907;
  color: #d5a179;
  font-family: "Amatic-Bold";
}

.puff-port input.invalid {
  border-color: #e74c3c;
  background-color: #270907;
  color: #d5a179;
}

.puff-port input.valid {
  border-color: #01423e;
  background-color: #270907;
}

.puff-port button {
  background-color: #270907;
  color: #d5a179;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.puff-port button:disabled {
  background-color: #614077;
  cursor: not-allowed;
}

.puff-port button:hover:not(:disabled) {
  background-color: #01423e;
}






@media (max-width: 768px) {

  .puff-port {
    width: 90%; /* Wider on smaller screens */
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #countdown {
    flex-direction: column; /* Stacks flex items vertically */
    align-items: center; /* Align items in the center of the container for smaller screens */
  }

  .time-section {
    margin-bottom: 20px; /* Optional: Adds some space between the stacked items */
  }

  .three-columns {
    grid-template-columns: 1fr; /* Makes the grid have only one column */
  }

  .image-grid {
    grid-template-columns: 1fr; /* Only one tile per row on smaller screens */
  }

  .top-food-chain-grid {
    grid-template-columns: 1fr; 
  }



  .scrolling-ipuff-pomages-wrapper {
    gap: 0em; /*  Reduces the space between columns */
    margin-left: 5%; /* Reduces the overall left margin */
    margin-right: 5%; /* Reduces the overall right margin */
}

  .image-scroll-container {
      height: 100%; /* Allows the container to adjust based on content */
      display: grid;
      grid-template-columns: repeat(3 1fr);
  }

  .scrolling-image {
      max-width: 100%; /* Ensures images do not stretch beyond their max width */
      height: auto; /* Adjusts height automatically */
  }

  .button-row {
      grid-template-rows: 1fr; /* Stacks button directly under images without extra space */
      /*margin-top: 10px; /* Reduces the space above the button */
  }

  .button-row h5 {
    font-family: "Bangers";
    font-size: 1em;
    color: #270907;
  }

  .image-scroll-container {
      padding: 0.1em; /* Reduces padding within each image container */
  }

  .middle-container-new p {
    font-family: 'Amatic-Bold';
    color: #270907;
    font-size: 1em;
    margin-top: -1%;
    text-align: center;
  }

  .middle-container h3 {
    font-size: 1.5em;
  }

  .top-container h1 {
    font-size: 2.5em;
  }



}
