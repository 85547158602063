/* Defining colors from the color palette */
:root {
  /* Original colors */
  --background-color: #281f24; /* Dark Background */
  --accent-color-1: #02534e; /* Green Accent */
  --accent-color-2: #efc9a2; /* Light Peach Accent */
  --complementary-color: #7a5195; /* Complementary Purple */

  /* Darker shades */
  --background-color-darker: #20181c; /* Darker Background */
  --accent-color-1-darker: #01423e; /* Darker Green Accent */
  --accent-color-2-darker: #bfa081; /* Darker Light Peach Accent */
  --complementary-color-darker: #614077; /* Darker Complementary Purple */

  /* Shades of Orange */
  --rich-orange: #bc906f;
  }

@font-face {
    font-family: 'Kalam-Light';
    src: url('../../assets/fonts/Kalam/Kalam-Light.ttf') format('truetype');
    font-weight: 300; /* Light */
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa-Light';
    src: url('../../assets/fonts/Comfortaa/static/Comfortaa-Light.ttf') format('truetype');
    font-weight: 300; /* Light */
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('../../assets/fonts/Comfortaa/static/Comfortaa-Regular.ttf') format('truetype');
    font-weight: 400; /* Regular */
    font-style: normal;
}



@font-face {
    font-family: 'Comfortaa-Bold';
    src: url('../../assets/fonts/Comfortaa/static/Comfortaa-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
}


@font-face {
    font-family: 'Kalam';
    src: url('../../assets/fonts/Kalam/Kalam-Regular.ttf') format('truetype');
    font-weight: normal; /* Regular */
    font-style: normal;
}

@font-face {
    font-family: 'Kalam-Bold';
    src: url('../../assets/fonts/Kalam/Kalam-Bold.ttf') format('truetype');
    font-weight: bold; /* Bold */
    font-style: normal;
}

@font-face {
    font-family: 'PermanentMarker';
    src: url('../../assets/fonts/PermanentMarker-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bangers';
    src: url('../../assets/fonts/Bangers/Bangers-Regular.ttf') format('truetype');
    font-weight: normal; /* Regular */
    font-style: normal;
  }

.body {
    color: var(--cream);
    background-color: #270907;
    font-family: 'Kalam';
}

.header {
    display: grid;
    grid-template-columns: auto 1fr auto; /* One column for the main content, and another for the sign-in section */
    align-items: center;
    /*background-color: #000d1d;  Dark background color */
    color: var(--cream);
    background-color: #270907;
    padding: 0 16px; /* Adjust padding as needed */
}

.button {
    background-color: #d5a179;
    color: #270907; 
    height: 2em; /* Fixed height for the button */
    padding: 0 1em; /* Horizontal padding of 1em, vertical padding removed */
    cursor: pointer;
    border: 2px solid var(--accent-color-2);
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-family: 'Comfortaa-Bold';
    display: inline-flex; /* Use inline-flex to maintain button's inline behavior */
    align-items: center; /* This aligns the text vertically */
    justify-content: center; /* This centers the text horizontally */
    /* Other styles */
}

.homepage a {
    text-decoration: none;
}

.button:hover {
    background-color: var(--accent-color-1-darker);
    border-color: #d5a179; 
    color: #d5a179;
}

.logo {
    /* 1em is equal to the current font-size. Since we want the logo 1.5 times the height of the button (2em), we multiply by 1.5 */
    height: 5em; /* This makes the logo 1.5 times the button height */
    width: auto; /* Keep the aspect ratio of the image */
    /* Adjust the margins as necessary */
    margin-right: 1em; /* Creates space between the logo and any other elements to its right */
}


.sign-in {
    display: flex;
    justify-content: flex-end; /* Aligns the sign-in content to the far right */
    align-items: center;
    gap: 10px; /* This creates a gap between the address and the button */
}

.address-text {
    margin-right: 10px; /* This will only take effect if you need extra spacing */
    font-family: 'Comfortaa'; 
    color: var(--accent-color-1);
}


.navigation {
    display: flex;
    justify-content: center; /* Center navigation links */
    align-items: center;
    padding: 0.5em 0; /* Add some vertical padding */
}

.nav-link {
    color: #d5a179;
    margin: 0 1em; /* Space out the links */
    text-decoration: none;
    font-family: 'Bangers';
    transition: color 0.3s ease;
    font-size: 2em;
}

.nav-link:hover {
    color: var(--accent-color-1);
}

.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Media query for mobile devices */
@media (max-width: 768px) {
    /* Existing mobile CSS... */

    .navigation {
        order: 2; /* Reorder navigation to come after logo */
        width: 100%; /* Full width to allow centering of links */
        justify-content: space-around; /* Evenly distribute space around links */
    }

    .header {
        grid-template-rows: auto 1fr auto; /* Adjust to fit the navigation row */
    }

    .header-top {
        flex-direction: column;
        align-items: center;
    }

    .sign-in {
        order: 3; /* Reorder sign-in to the bottom */
        flex-direction: column; /* Stack sign-in items */
    }
}

@media (max-width: 768px) {
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .header-top {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .navigation {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .sign-in {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
    }

    .nav-link {
        font-size: 1.5em; /* Adjust the font size if necessary */
    }
}

  /* If you're not using any other content in the header and want the sign-in to be the only thing aligned to the right, you could remove the '1fr' from the 'grid-template-columns' property in the .header class. */
  