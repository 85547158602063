:root {
    --gold: #FFD700;
    --soft-gold: #cba135;
    --light-blue: #41729F;
    --midnight-blue: #001f3f;
    --royal-blue: #0047ab;
    --cream: #f4f1de; 
}


@font-face {
    font-family: 'Comfortaa-Light';
    src: url('../../assets/fonts/Comfortaa/static/Comfortaa-Light.ttf') format('truetype');
    font-weight: 300; /* Light */
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('../../assets/fonts/Comfortaa/static/Comfortaa-Regular.ttf') format('truetype');
    font-weight: 400; /* Regular */
    font-style: normal;
}



@font-face {
    font-family: 'Comfortaa-Bold';
    src: url('../../assets/fonts/Comfortaa/static/Comfortaa-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
}

.footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Creates three columns of equal width */
    background-color: #270907;
    padding: 2% 0px;
    align-items: center; /* Vertically align items within the footer */
    justify-items: center; /* Horizontally center aligns the content of the grid items */
}

.social-links a {
    margin: 0 10px; /* Adds space between each icon */
    color: #d5a179; /* Example to style the color */
}

.social-links i { /* Targeting the icons directly if they are in <i> tags */
    font-size: 4em; /* Increase the size of the icons */
    vertical-align: middle; /* Aligns icons vertically if needed */
}

/* Additional hover effect for visual feedback */
.social-links a:hover {
    color: #02534e; /* Change color on hover */
}

.text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.text p {
    font-family: 'Comfortaa';
    color: #d5a179;
    font-size: 12pt;
}
/*
.logo-foot {
    width: auto;
    align-self: stretch; /* Match the height to the text columns 
}*/
.text-left p {
    font-family: 'Comfortaa';
    color: #d5a179;
    font-size: 12pt;
    
}

.logo-foot img {
    height: 10em; 
    width: auto;
    object-fit: contain;
}

.text p:after {
    content: ' \00A9 2024'; /* Unicode character for copyright symbol (©) */
} 


@media (max-width: 768px) {
    .footer {
        grid-template-columns: 1fr; /* Stack the children vertically on smaller screens */
        padding-top: 25%;
    }
    .text-left p {
        padding-left: 0; /* Adjust the padding for mobile to remove extra space */
    }
}